<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Setting Ot All In</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Employee</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Day Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Category OT</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Clock In</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Clock Out</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Rate</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td>
                        <router-link :to="/detail-profile/+row.nik">
                          <div class="d-flex px-2 py-1">
                            <div>
                              <img
                                v-if="row.foto_karyawan"
                                :src="storageUrl+'/foto_karyawan/'+row.foto_karyawan"
                                class="avatar avatar-sm me-3"
                                alt="user1"
                              />
                              <img
                                v-else
                                src="../../assets/img/avatar_karyawan.png"
                                class="avatar avatar-sm me-3"
                                alt="user1"
                              />
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                              <p class="text-xs text-secondary mb-0">{{ row.position}}</p>
                            </div>
                          </div>
                        </router-link>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.type_hari}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.category_ot }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.jam_masuk}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.jam_keluar }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.rate) }}</span>
                      </td>
                      <td>
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" ></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <span class="font-weight-bold">TOTAL ROWS: {{ table.data.length }}</span>
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee <span style="color: red;">*</span></label>
        <multiselect v-model="otAllInData.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Day Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="otAllInData.type_hari">
          <option value="Biasa">Biasa</option>
          <option value="Libur">Libur</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">OT Category <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="otAllInData.category_ot">
          <option value="Normal">Normal</option>
          <option value="Countinous">Countinous</option>
          <option value="Night Shift">Night Shift</option>
          <option value="Half Day">Half Day</option>
          <option value="Full Day">Full Day</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Clock In <span style="color: red;">*</span></label>
        <input type="time" class="form-control" placeholder="Clock In" v-model="otAllInData.jam_masuk" required>
        <label for="example-text-input" class="form-control-label mt-3">Clock Out <span style="color: red;">*</span></label>
        <input type="time" class="form-control" placeholder="Clock Out" v-model="otAllInData.jam_keluar">
        <label for="example-text-input" class="form-control-label mt-3">Rate <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Rate" v-model="otAllInData.rate">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee </label>
        <multiselect v-model="search.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Day Type </label>
        <select class="form-select" aria-label="Default select example" v-model="search.type_hari">
          <option value="Biasa">Biasa</option>
          <option value="Libur">Libur</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">OT Category </label>
        <select class="form-select" aria-label="Default select example" v-model="search.category_ot">
          <option value="Normal">Normal</option>
          <option value="Countinous">Countinous</option>
          <option value="Night Shift">Night Shift</option>
          <option value="Half Day">Half Day</option>
          <option value="Full Day">Full Day</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilter.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
import settingOtAllIn from '@/services/human_resource/settingOtAllIn.service';
import employee from '@/services/human_resource/employee.service';
import akun from '@/services/akun.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Karyawan",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      storageUrl : config.storageUrl,
      otAllInData: {},
      search: {
        employee: '',
        type_hari: '',
        category_ot: '',
      },
      apiUrl :config.apiUrl,
      role: '',
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    // this.getRole();
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, settingOtAllIn.index({employee: context.search.employee['nik'], type_hari: context.search.type_hari, category_ot: context.search.category_ot})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role = response.data.data[0].role;
          if (response.data.data[0].role != 'Admin') {
            context.$router.push({ path: /detail-profile/+response.data.data[0].employee_id})
          }
      }).onError(function(error) {  
      })
      .call() 
    },
    getEmployee(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.karyawan   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, settingOtAllIn.show(id)).onSuccess(function(response) {
          context.otAllInData          = response.data.data.data;
          context.otAllInData.employee = response.data.data.dataEmployee
          context.form.show            = true;
          context.form.title           = 'Edit Data';       
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.otAllInData.employee != undefined && context.otAllInData.type_hari != undefined && context.otAllInData.category_ot != undefined && context.otAllInData.jam_masuk != undefined && context.otAllInData.jam_keluar != undefined && context.otAllInData.rate != undefined) {
        formData.append('employee', JSON.stringify(context.otAllInData.employee));
        formData.append('type_hari', context.otAllInData.type_hari);
        formData.append('category_ot', context.otAllInData.category_ot);
        formData.append('jam_masuk', context.otAllInData.jam_masuk);
        formData.append('jam_keluar', context.otAllInData.jam_keluar);
        formData.append('rate', context.otAllInData.rate);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, settingOtAllIn.create(formData));
      } else {
          api = Api(context, settingOtAllIn.update(this.otAllInData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Anda yakin ingin menghapus data ini ?");
      if (r == true) {
        let context = this;

        Api(context, settingOtAllIn.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Karyawan";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, settingOtAllIn.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
