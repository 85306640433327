import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('setting-ot-all-in/index', {params});
    },
    show(id) {
        return Service().get('setting-ot-all-in/show/'+id);
    },
    create(params) {
        return Service().post('setting-ot-all-in/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('setting-ot-all-in/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('setting-ot-all-in/delete/'+id);
    },
    import(params) {
        return Service().post('import/setting-ot-all-in', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}