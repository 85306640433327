import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('request-for-quotation/index', {params});
    },
    searchRfqWherePrVendor(params) {
        return Service().get('request-for-quotation/get-request-for-quotation-where-pr-vendor', {params});
    },
    generateRfqCode(params) {
        return Service().get('request-for-quotation/generate-rfq-code', {params});
    },
    create(params) {
        return Service().post('request-for-quotation/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id, params) {
        return Service().post('request-for-quotation/delete/'+id, params);
    },
    // ------- ITEM ROUTE -----
    updateItem(id, params) {
        return Service().post('request-for-quotation/update-item-rfq-vendor/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    transactionDetail(params) {
        return Service().get('request-for-quotation/transaction-detail', {params});
    },
    showItem(id) {
        return Service().get('request-for-quotation/show-item/'+id);
    },
    deleteItem(id, params) {
        return Service().post('request-for-quotation/delete-item/'+id, params);
    },
    rfqApproved(params) {
        return Service().post('request-for-quotation/rfq-approved', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    cekNpwp(params) {
        return Service().get('request-for-quotation/cek-npwp', {params});
    },
    deleteVendor(id, params) {
        return Service().post('request-for-quotation/delete-vendor/'+id, params);
    },
}