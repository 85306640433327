<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/inventory-transaction?pr_code='+search.pr_code+'&transaction_type='+search.transaction_type+'&po_number='+search.po_number+'&delivery_order_number='+search.delivery_order_number+'&supplier='+search.supplier['name']+'&project='+search.project['project_number']+'&user='+search.user['name']+'&item_name='+search.item_name['item_name']+'&reducted_select='+search.reducted_select+'&category_reduction='+search.category_reduction+'&pic_warehouse='+search.pic_warehouse['name']+'&warehouse_location='+search.warehouse_location['name']+'&date='+search.date+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Purchase Requisition</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="createWithSubForm()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PR NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Project</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">WO NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MATERIAL GROUP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DATE REQUIRED</th>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">GENERAL NOTE</th> -->
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TOTAL ITEM</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">STATUS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" @click="getDetailTransaction(row.pr_code)" style="cursor: pointer;">
                        <span class="badge badge-sm bg-gradient-primary">  {{ row.pr_code }} <span v-if="row.total_revision"> (Rev. {{ row.total_revision }})</span> </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.project_type }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.wo_code }} - {{ row.project }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.material_group_code }} - {{ row.group_name }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.date_required).locale('id').format('LL') }} </span>
                      </td>
                      <!-- <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.general_note }} </span>
                      </td> -->
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.item_no }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.total_item_count }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'PR Approved'"> {{ row.status }} </span>
                        <span class="badge badge-sm bg-gradient-info" v-else> {{ row.status }} </span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fa fa-check-circle-o" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="prApproved(row.pr_code)"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id, row.total_item_count, row.transaction_type)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- ===========================================  MODAL ADD DATA WITH SUB FORM =============================================== -->
  <div class="container">
    <vue-final-modal v-model="formSub.show" classes="modal-container" content-class="modal-content-width" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 text-center">
          <span class="modal__title">{{formSub.title}} <template v-if="headerData.transaction_type">{{ headerData.transaction_type.toUpperCase() }} ====</template></span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formSub.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="row">
          <div class="col-10 text-center">
          </div>
          <div class="col-2 float-right">
            <a class="btn btn-sm btn-warning" style="margin-left: 0px;" :href="apiUrl+'print-pdf/pr-detail?pr_code='+headerData.pr_no+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print</a>
          </div>
        </div>
        <table class="table table-sm table-bordered">
          <tbody>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> PR NO </td>
              <td> <input type="text" class="form-control" placeholder="PR NO" v-model="headerData.pr_code_rev" disabled> </td>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> STATUS <span style="color: red;">*</span></td>
              <td> <input type="text" class="form-control" placeholder="Status" v-model="headerData.status" disabled> </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> PR TYPE <span style="color: red;">*</span></td>
              <td>
                <select class="form-select" aria-label="Default select example" v-model="headerData.project_type" :disabled="formSub.title == '==== TRANSACTION PR DETAIL ===='">
                  <option value="Project">Project</option>
                  <option value="Non Project">Non Project</option>
                </select>
              </td>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> DATE REQUIRED <span style="color: red;">*</span></td>
              <td width="300">  <input type="date" class="form-control" placeholder="Date Required" v-model="headerData.date_required"> </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> WO NO <span style="color: red;">*</span></td>
              <td colspan="4"> 
                <multiselect v-model="headerData.wo_no" :options="tableWo.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Work Order No" label="wo_code" track-by="wo_code" :preselect-first="true" @keyup="getWo" :custom-label="nameWithLangWo" @select="setByWO()" :disabled="formSub.title == '==== TRANSACTION PR DETAIL ===='">
                  <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                </multiselect> 
              </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> MTR / SERVC GRP PR <span style="color: red;">*</span></td>
              <td colspan="4"> 
                <multiselect v-model="headerData.material_group_code" :options="tableMaterialGroupPR.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Material / Service Group PR" label="material_group_code" track-by="material_group_code" :preselect-first="true" @keyup="getMaterialGroupPR" :custom-label="nameWithLangMGPr" :disabled="formSub.title == '==== TRANSACTION PR DETAIL ===='">
                  <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                </multiselect> 
              </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> CLIENT <span style="color: red;">*</span></td>
              <td width="300">  <input type="text" class="form-control" placeholder="Client" v-model="headerData.client" disabled> 
              </td>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> CONTRACT DELIVERY <span style="color: red;">*</span></td>
              <td> <input type="text" class="form-control" placeholder="Contract Delivery" v-model="headerData.contract_delivery" disabled> </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> Attachment </td>
              <td colspan="2"> <input type="file" accept="application/pdf" class="form-control" placeholder="Attachment" @change="filesChange"> </td>
              <td class="align-middle text-center"><a :href="storageUrl+'attachment_pr/'+headerData.attachment" target="_BLANK" style="color: blue; size: 5px;">{{ headerData.attachmentView }} ...</a></td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> GENERAL NOTE </td>
              <td colspan="3" rowspan="3">
                <textarea rows="4" class="form-control" v-model="headerData.general_note" placeholder="General Note"></textarea>
              </td>
            </tr>
            
          </tbody>
        </table>
          <!-- ===== FOR ITEM LIST ===== -->
          <div>
            <div class="">
              <hr style="border: 2px solid #008000;">
              <div class="row mb-2">
                <div class="col-lg-6">
                  <div class="card-header pb-0">
                    <h6><i class="fas fa-list-ol" style="margin-right: 10px;"></i> Item List</h6>
                  </div>
                </div>
                <div class="col-lg-6">
                  <argon-button
                    class="mt-4"
                    variant="gradient"
                    color="warning"
                    size="sm"
                    @click="prApproved(headerData.pr_no)"
                    :disabled="onLoading"
                  >
                    <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                    <span v-else>
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i> PR Approved
                    </span>
                  </argon-button>
                  <argon-button color="info" size="sm" class="mt-4" style="margin-left: 10px;" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button>
                  <argon-button
                    class="mt-4"
                    style="margin-left: 10px;"
                    variant="gradient"
                    color="success"
                    size="sm"
                    @click="createItem()"
                  ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                </div>
              </div>
              <div class="table-responsive p-0 scroll-model">
                <table class="table table-hover table-striped table-bordered align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Image</th> -->
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Part Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Specification</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Size</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Unit</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Qty</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Weight</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Rev.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MPS NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">USE FOR</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TRACKING</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">STATUS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in dataListItem.data" :key="i">
                      <td class="align-middle text-center text-sm border">
                        <!-- <span class="text-secondary text-xs font-weight-bold">{{ row.no_item }}</span> -->
                        <input type="text" class="form-control input-sm" placeholder="No" @change="editItem" :value="row.no_item" :id="row.id">
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.part_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.specification }}</span>
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.size }}</span>
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.unit }}</span>
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <!-- <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span> -->
                        <input type="number" class="form-control input-sm" placeholder="Qty"  @change="editItem" :value="row.qty" :id="row.id">
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <!-- <span class="text-secondary text-xs font-weight-bold">{{ row.weight }}</span> --> 
                        <input type="number" class="form-control input-sm" placeholder="Weight" @change="editItem" :value="row.weight" :id="row.id">
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <span class="text-xs font-weight-bold" style="color: blue; cursor: pointer;" @click="showRevision(row.item_pr_code)">{{ row.revisi_item }}</span>
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <!-- <span class="text-secondary text-xs font-weight-bold">{{ row.mps_no }}</span> -->
                        <input type="text" class="form-control input-sm" placeholder="Mps No" @change="editItem" :value="row.mps_no" :id="row.id">
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <!-- <span class="text-secondary text-xs font-weight-bold">{{ row.item_no }}</span> -->
                        <input type="text" class="form-control input-sm" placeholder="Item No" @change="editItem" :value="row.item_no" :id="row.id">
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <!-- <span class="text-secondary text-xs font-weight-bold">{{ row.use_for }}</span> -->
                        <input type="text" class="form-control input-sm" size="50" placeholder="Use For" @change="editItem" :value="row.use_for" :id="row.id">
                      </td>
                      <td class="align-middle text-center text-sm border">
                        <span class="badge bg-gradient-info">{{ row.status }}</span>
                      </td>
                       <td class="align-middle text-center text-sm border">
                        <!-- <span class="badge bg-gradient-secondary">{{ row.status_item }}</span> -->
                        <select class="form-select" aria-label="Default select example" @change="editItem" :value="row.status_item" :id="row.id">
                          <option value="F/R">F/R - FOR REFERENCE</option>
                          <option value="F/W">F/W - FOR WORKING</option>
                          <option value="H">H - HOLD</option>
                          <option value="R">R - RELEASE</option>
                          <option value="C.L">C.L - CANCELLED</option>
                        </select>
                      </td>
                      <td class="align-middle text-center border">
                        <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editItem(row.id)" title="Edit" v-if="row.status == 'Draf'"></i> -->
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="removeItem(row.id)" v-if="row.status == 'Draf'"></i>
                        <i class="fa fa-deaf fa-sm" aria-hidden="true" title="Revision" style="cursor: pointer; margin-right: 20px;" @click="revisiItem(row.id)" v-if="row.status != 'Draf'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="headerData = {}">Clear</argon-button> -->
        </div>
        <div class="col-1">
          <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button> -->
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL ADD DATA ITEM ======= -->
  <div class="container">
    <vue-final-modal v-model="formAddItem.show" classes="modal-container" content-class="modal-content-width" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAddItem.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAddItem.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="table-responsive p-0 scroll-model">
          <input type="text" class="form-control mb-4" placeholder="Search Part Name ..." @keyup="createItem()" v-model="searchPartName">
          <table class="table table-hover table-striped table-bordered align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Code</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Part Name</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Specification</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Size</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableItemList.data" :key="i">
                <td class="align-middle text-center border">
                  <i class="fa fa-plus-circle fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="setAddItem(row.item_code, row.item_name, row.specification, row.size, row.unit)" title="Add Item"></i>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.item_code }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.item_name }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.specification }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.size }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.unit }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <!-- KONSEP OLD -->
        <!-- <label for="example-text-input" class="form-control-label mt-3">No <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="No" v-model="itemData.no_item">
        <label for="example-text-input" class="form-control-label mt-3">Part Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Part Name" v-model="itemData.part_name">
        <label for="example-text-input" class="form-control-label mt-3">Specification <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Specification" v-model="itemData.specification">
        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Size" v-model="itemData.size">
        <label for="example-text-input" class="form-control-label mt-3">Unit <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="itemData.unit">
          <option value="Batang">Batang</option>
          <option value="Box">Box</option> 
          <option value="Btl">Btl</option>
          <option value="Can">Can</option>
          <option value="Cyl">Cyl</option>
          <option value="Drum">Drum</option>
          <option value="Ea">Ea</option>
          <option value="Kg">Kg</option>
          <option value="Kaleng">Kaleng</option>
          <option value="Lembar">Lembar</option>
          <option value="Liter">Liter</option>
          <option value="Lusin">Lusin</option>
          <option value="Mtr">Mtr</option>
          <option value="M3">M3</option>
          <option value="Pack">Pack</option>
          <option value="Pasang">Pasang</option>
          <option value="Pcs">Pcs</option>
          <option value="Rim">Rim</option>
          <option value="Roll">Roll</option>
          <option value="Set">Set</option>
          <option value="Tbg">Tbg</option>
          <option value="Unit">Unit</option>        
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty" v-model="itemData.qty">
        <label for="example-text-input" class="form-control-label mt-3">Weight </label>
        <input type="number" class="form-control" placeholder="Weight" v-model="itemData.weight"> -->
        <!-- <label for="example-text-input" class="form-control-label mt-3">Rev. </label> -->
        <!-- <input type="number" class="form-control" placeholder="Rev." v-model="itemData.revisi_item"> -->
        <!-- <label for="example-text-input" class="form-control-label mt-3">MPS No </label>
        <input type="text" class="form-control" placeholder="MPS No" v-model="itemData.mps_no">
        <label for="example-text-input" class="form-control-label mt-3">Item No </label>
        <input type="text" class="form-control" placeholder="Item No" v-model="itemData.item_no">
        <label for="example-text-input" class="form-control-label mt-3">Use For </label>
        <input type="text" class="form-control" placeholder="Use For" v-model="itemData.use_for">
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="itemData.status_item">
          <option value="F/R">F/R - FOR REFERENCE</option>
          <option value="F/W">F/W - FOR WORKING</option>
          <option value="H">H - HOLD</option>
          <option value="R">R - RELEASE</option>
          <option value="C.L">C.L - CANCELLED</option>
        </select> -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="itemData = {}, tableItem.data = []">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL REVISION ITEM ======= -->
  <div class="container">
    <vue-final-modal v-model="formRevisiItem.show" classes="modal-container" content-class="modal-content" :z-index="100000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formRevisiItem.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formRevisiItem.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">No <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="No" v-model="itemData.no_item">
        <label for="example-text-input" class="form-control-label mt-3">Part Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Part Name" v-model="itemData.part_name">
        <label for="example-text-input" class="form-control-label mt-3">Specification <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Specification" v-model="itemData.specification">
        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Size" v-model="itemData.size">
        <label for="example-text-input" class="form-control-label mt-3">Unit <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="itemData.unit">
          <option value="Batang">Batang</option>
          <option value="Box">Box</option> 
          <option value="Btl">Btl</option>
          <option value="Can">Can</option>
          <option value="Cyl">Cyl</option>
          <option value="Drum">Drum</option>
          <option value="Ea">Ea</option>
          <option value="Kg">Kg</option>
          <option value="Kaleng">Kaleng</option>
          <option value="Lembar">Lembar</option>
          <option value="Liter">Liter</option>
          <option value="Lusin">Lusin</option>
          <option value="Mtr">Mtr</option>
          <option value="M3">M3</option>
          <option value="Pack">Pack</option>
          <option value="Pasang">Pasang</option>
          <option value="Pcs">Pcs</option>
          <option value="Rim">Rim</option>
          <option value="Roll">Roll</option>
          <option value="Set">Set</option>
          <option value="Tbg">Tbg</option>
          <option value="Unit">Unit</option>        
        </select>

        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty" v-model="itemData.qty">
        <label for="example-text-input" class="form-control-label mt-3">Weight </label>
        <input type="number" class="form-control" placeholder="Weight" v-model="itemData.weight">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Rev. </label> -->
        <!-- <input type="number" class="form-control" placeholder="Rev." v-model="itemData.revisi_item"> -->
        <label for="example-text-input" class="form-control-label mt-3">MPS No </label>
        <input type="text" class="form-control" placeholder="MPS No" v-model="itemData.mps_no">
        <label for="example-text-input" class="form-control-label mt-3">Item No </label>
        <input type="text" class="form-control" placeholder="Item No" v-model="itemData.item_no">
        <label for="example-text-input" class="form-control-label mt-3">Use For </label>
        <input type="text" class="form-control" placeholder="Use For" v-model="itemData.use_for">
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="itemData.status_item">
          <option value="F/R">F/R - FOR REFERENCE</option>
          <option value="F/W">F/W - FOR WORKING</option>
          <option value="H">H - HOLD</option>
          <option value="R">R - RELEASE</option>
          <option value="C.L">C.L - CANCELLED</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Reason For Revision <span style="color: red;">*</span></label>
        <textarea rows="4" class="form-control" v-model="itemData.reason_revision" placeholder="Reason For Revision"></textarea>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="itemData = {}, tableItem.data = []">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveRevision()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL SHOW REVISION ======= -->
  <div class="container">
    <vue-final-modal v-model="formShowRevision.show" classes="modal-container" content-class="modal-content" :z-index="100000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formShowRevision.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formShowRevision.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
        <div class="table-responsive p-0 scroll-model">
          <table class="table table-hover table-striped table-bordered align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Revision</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Reason</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Revision At</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Revision By</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableShowRevision.data" :key="i">
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                </td>
                <td class="align-middle text-left text-sm border">
                  <span class="text-secondary text-xs font-weight-bold" v-html="row.revision"></span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.reason_revision }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ moment(row.created_at).locale('id').format('LLL') }}</span>
                </td>
                <td class="align-middle text-center text-sm border">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.created_by }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      <!-- footer -->
      <div class="row float-right mt-3">
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- ================================================  MODAL FILTER ============================================== -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">PR NO </label>
        <input type="text" class="form-control" placeholder="PR NO" v-model="search.pr_code">
        <label for="example-text-input" class="form-control-label mt-3">PR Type </label>
        <select class="form-select" aria-label="Default select example" v-model="search.project_type">
          <option value="Project">Project</option>
          <option value="Non Project">Non Project</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">WO NO </label>
        <multiselect v-model="search.wo_code" :options="tableWo.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Work Order No" label="wo_code" track-by="wo_code" :preselect-first="true" @keyup="getWo" :custom-label="nameWithLangWo">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect> 
        <label for="example-text-input" class="form-control-label mt-3">MATERIAL GROUP </label>
        <multiselect v-model="search.material_group_code" :options="tableMaterialGroupPR.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Material / Service Group PR" label="material_group_code" track-by="material_group_code" :preselect-first="true" @keyup="getMaterialGroupPR" :custom-label="nameWithLangMGPr">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect> 
        <label for="example-text-input" class="form-control-label mt-3">Date Required </label>
        <input type="text" class="form-control" placeholder="Date Required" v-model="search.date_required">
        <label for="example-text-input" class="form-control-label mt-3">General Note </label>
        <input type="text" class="form-control" placeholder="General Note" v-model="search.general_note">
        <label for="example-text-input" class="form-control-label mt-3">Item No </label>
        <input type="text" class="form-control" placeholder="Item No" v-model="search.item_no">
        <label for="example-text-input" class="form-control-label mt-3">Tracking </label>
        <select class="form-select" aria-label="Default select example" v-model="search.status">
          <option value="Draf">Draf</option>
          <option value="PR Approved">PR Approved</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChangeImport" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import PR Item.xlsx'" style="color: blue;"> Import PR Item</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">No</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Column</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Error</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Row</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ i + 1 }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import purchasingRequisition from '@/services/procurement/purchasingRequisition.service';
import workOrder from '@/services/procurement/workOrder.service';
import materialGroupPR from '@/services/procurement/materialGroupPR.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableItemList: {
        data: []
      },
      tableShowRevision: {
        data: []
      },
      formSub: {
        add: true,
        title: "==== TRANSACTION PR DETAIL ====",
        show: false
      },
      formAddItem: {
        add: true,
        title: "==== TRANSACTION PR DETAIL ====",
        show: false
      },
      formRevisiItem: {
        add: true,
        title: "==== PR ITEM REVISON ====",
        show: false
      },
      formShowRevision: {
        add: true,
        title: "==== SHOW REVISON ====",
        show: false
      },
      formImport: {
        add: true,
        title: "Import PR Item",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      headerData: {},
      search: {
        pr_code: '',
        project_type: '',
        wo_code: '',
        material_group_code: '',
        date_required: '',
        general_note: '',
        item_no: '',
        status: '',
      },
      apiUrl :config.apiUrl,
      tableWo: {
        data: []
      },
      tableMaterialGroupPR: {
        data: []
      },
      tableItem: {
        data: []
      },

      // ----- Item Transaction -----
      dataTransaction: {},
      dataListItem: {
        data: []
      },
      itemData: {},
      searchPartName: '',
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLangWo ({ wo_code, project }) {
      return `${wo_code} - ${project}`
    },
    nameWithLangMGPr ({ material_group_code, group_name }) {
      return `${material_group_code} - ${group_name}`
    },
    get(param){
      let context = this; 
      context.onLoading = true;

      Api(context, purchasingRequisition.index({pr_code: this.search.pr_code, project_type: this.search.project_type, wo_code: this.search.wo_code['wo_code'], material_group_code: this.search.material_group_code['material_group_code'], date_required: this.search.date_required, general_note: this.search.general_note, item_no: this.search.item_no, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show = false;
         context.onLoading       = false;
      })
      .call()
    },
    getWo(param){
      if (param.target.value.length >= 3) {
        let context = this;        
        Api(context, workOrder.getWo({search: param.target.value})).onSuccess(function(response) {    
            context.tableWo.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableWo.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    getMaterialGroupPR(param){
      if (param.target.value.length >= 1) {
        let context = this;        
        Api(context, materialGroupPR.getMaterialGroupPR({search: param.target.value})).onSuccess(function(response) {    
            context.tableMaterialGroupPR.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableMaterialGroupPR.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    getDetailTransaction(trxnumber){
      let context = this;               
      Api(context, purchasingRequisition.transactionDetail({pr_code: trxnumber})).onSuccess(function(response) {    
          context.headerData.id                  = response.data.data.data.id;
          if (response.data.data.data.total_revision) {
            context.headerData.pr_code_rev = response.data.data.data.pr_code + '(Rev.'+response.data.data.data.total_revision+')';

          }else{
            context.headerData.pr_code_rev = response.data.data.data.pr_code;
          }
          context.headerData.pr_no               = response.data.data.data.pr_code;
          context.headerData.project_type        = response.data.data.data.project_type;
          context.headerData.wo_no               = response.data.data.data.wo_code;
          context.headerData.material_group_code = response.data.data.data.material_group_code;
          context.headerData.date_required       = response.data.data.data.date_required;
          context.headerData.status              = response.data.data.data.status;
          context.headerData.general_note        = response.data.data.data.general_note;
          context.headerData.item_no             = response.data.data.data.item_no;
          context.headerData.client              = response.data.data.data.client;
          context.headerData.contract_delivery   = response.data.data.data.contract_delivery;
          context.headerData.attachment          = response.data.data.data.attachment;
          context.headerData.attachmentView      = response.data.data.data.attachment.substring(0,30);

          context.headerData.wo_no               = response.data.data.dataWo;
          context.headerData.material_group_code = response.data.data.dataMGPR;

          context.dataListItem.data = response.data.data.dataListItem;

          context.formSub.add   = true;
          context.formSub.show  = true;
          context.formSub.title = "==== TRANSACTION PR DETAIL ====";

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.headerData = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    setByWO(){
      this.headerData.client            = this.headerData.wo_no['client'];
      this.headerData.contract_delivery = this.headerData.wo_no['due_date'];
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    createWithSubForm() {
      this.formSub.add       = true;
      this.formSub.show      = true;
      this.formSub.title     = "==== TRANSACTION PR ADD DATA ====";
      this.headerData        = {};
      this.headerData.status = 'Draf',
      this.dataListItem.data = {}
      this.defaultDate()
    },
    createItem() {
      this.formAddItem.add   = true;
      this.formAddItem.show  = true;
      this.formAddItem.title = "Add Item";
      // this.itemData          = {};
      let context = this;           
      Api(context, purchasingRequisition.getItemGroup({item_group: context.headerData.material_group_code.material_group_code, part_name: context.searchPartName})).onSuccess(function(response) {
          context.tableItemList.data = response.data.data.data;  
      })
      .call()
    },
    editItem(param) {
      let id          = param.target.id
      let placeholder = param.target.placeholder


      let context = this;               
      Api(context, purchasingRequisition.showItem(id)).onSuccess(function(response) {
          context.itemData              = response.data.data;
          context.itemData.item_pr_code = response.data.data.item_pr_code;
          // context.formAddItem.show      = true;
          // context.formAddItem.title     = 'Edit Data';  

          if (placeholder == 'No') {
            context.itemData.no_item = param.target.value;
          }
          if (placeholder == 'Qty') {
            context.itemData.qty = param.target.value;
          }
          if (placeholder == 'Weight') {
            context.itemData.weight = param.target.value;
          }
          if (placeholder == 'Mps No') {
            context.itemData.mps_no = param.target.value;
          }
          if (placeholder == 'Item No') {
            context.itemData.item_no = param.target.value;
          }
          if (placeholder == 'Use For') {
            context.itemData.use_for = param.target.value;
          }else{
            context.itemData.status_item = param.target.value;
          }
          context.save() 
      })  
      .call()      
    },
    revisiItem(id) {
      let context = this;               
      Api(context, purchasingRequisition.showItem(id)).onSuccess(function(response) {
          context.itemData              = response.data.data;
          context.itemData.item_pr_code = response.data.data.item_pr_code;
          context.formRevisiItem.show   = true;
          context.formRevisiItem.title  = 'PR ITEM REVISON';   
      })
      .call()        
    },
    filesChange(e) {
      console.log(e.target.files[0])
      this.headerData.attachment = e.target.files[0];
    },
    setAddItem(item_code, item_name, specification, size, unit){
      let context  = this;
      this.itemData = {};

      if (context.headerData.project_type == 'Non Project') {
        context.itemData.no_item = item_code
      }else{
        context.itemData.no_item = ''
      }
      context.itemData.part_name     = item_name
      context.itemData.specification = specification
      context.itemData.size          = size
      context.itemData.unit          = unit
      context.itemData.status_item   = 'F/W'
      context.save();
    },
    save(id){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      console.log(context.headerData.project_type, context.headerData.wo_no, context.headerData.material_group_code, context.headerData.client, context.headerData.date_required, context.headerData.contract_delivery, context.headerData.status)

      // INSERT HEADER
      if (context.headerData.project_type != undefined && context.headerData.wo_no != undefined && context.headerData.material_group_code != undefined && context.headerData.client != undefined && context.headerData.date_required != undefined && context.headerData.contract_delivery != undefined && context.headerData.status != undefined ) {
        formData.append('pr_no', (context.headerData.pr_no == undefined) ? '' : context.headerData.pr_no);
        formData.append('project_type', context.headerData.project_type);
        formData.append('wo_no', JSON.stringify(context.headerData.wo_no));
        formData.append('material_group_code', JSON.stringify(context.headerData.material_group_code));
        formData.append('date_required', context.headerData.date_required);
        formData.append('status', context.headerData.status);
        formData.append('general_note', (context.headerData.general_note == undefined) ? '-' : context.headerData.general_note);
        formData.append('client', context.headerData.client);
        formData.append('contract_delivery', context.headerData.contract_delivery);
        formData.append('item_no', (context.headerData.item_no == undefined) ? '-' : context.headerData.item_no);
        formData.append('attachment', (context.headerData.attachment == undefined) ? '' : context.headerData.attachment);
      }else{
        return alert('The Red Star Field Header is Mandatory')
      }

      console.log(context.itemData.no_item, context.itemData.part_name, context.itemData.specification, context.itemData.size, context.itemData.unit, context.itemData.qty, context.itemData.status_item)
      // INSERT ITEM
      if (context.itemData.part_name != undefined && context.itemData.specification != undefined && context.itemData.size != undefined && context.itemData.unit != undefined) {
        formData.append('no_item', context.itemData.no_item);
        formData.append('item_pr_code', (context.itemData.item_pr_code == undefined) ? '' : context.itemData.item_pr_code);
        formData.append('part_name', context.itemData.part_name);
        formData.append('specification', context.itemData.specification);
        formData.append('size', context.itemData.size);
        formData.append('unit', context.itemData.unit);
        formData.append('qty', context.itemData.qty);
        formData.append('weight', (context.itemData.weight == undefined) ? '' : context.itemData.weight);
        formData.append('revisi_item', (context.itemData.revisi_item == undefined) ? '' : context.itemData.revisi_item);
        formData.append('mps_no', (context.itemData.mps_no == undefined) ? '-' : context.itemData.mps_no);
        formData.append('item_no', (context.itemData.item_no == undefined) ? '-' : context.itemData.item_no);
        formData.append('use_for', (context.itemData.use_for == undefined) ? '-' : context.itemData.use_for);
        formData.append('status_item', context.itemData.status_item);
        // formData.append('attachment', context.itemData.attachment);
      }else{
        return alert('The Red Star Field Item is Mandatory')
      }

      if (context.formSub.title == '==== TRANSACTION PR ADD DATA ') {
          api = Api(context, purchasingRequisition.create(formData));
      } else {     
          api = Api(context, purchasingRequisition.create(formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.getDetailTransaction(response.data.data.pr_code);
        context.notify((context.formSub.title === '==== TRANSACTION PR ADD DATA ') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.formSub.title != '==== TRANSACTION PR ADD DATA ') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.formAddItem.show = false;
      })
      .call();
    },
    saveRevision(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      // INSERT ITEM
      if (context.itemData.no_item != undefined && context.itemData.part_name != undefined && context.itemData.specification != undefined && context.itemData.size != undefined && context.itemData.unit != undefined && context.itemData.qty != undefined && context.itemData.status_item != undefined && context.itemData.reason_revision != undefined) {
        formData.append('no_item', context.itemData.no_item);
        formData.append('item_pr_code', (context.itemData.item_pr_code == undefined) ? '' : context.itemData.item_pr_code);
        formData.append('part_name', context.itemData.part_name);
        formData.append('specification', context.itemData.specification);
        formData.append('size', context.itemData.size);
        formData.append('unit', context.itemData.unit);
        formData.append('qty', context.itemData.qty);
        formData.append('weight', (context.itemData.weight == undefined) ? '' : context.itemData.weight);
        formData.append('revisi_item', (context.itemData.revisi_item == undefined) ? '' : context.itemData.revisi_item);
        formData.append('mps_no', (context.itemData.mps_no == undefined) ? '-' : context.itemData.mps_no);
        formData.append('item_no', (context.itemData.item_no == undefined) ? '-' : context.itemData.item_no);
        formData.append('use_for', (context.itemData.use_for == undefined) ? '-' : context.itemData.use_for);
        formData.append('status_item', context.itemData.status_item);
        formData.append('reason_revision', context.itemData.reason_revision);
        formData.append('pr_code', context.headerData.pr_no);
        // formData.append('attachment', context.itemData.attachment);
      }else{
        return alert('The Red Star Field Item is Mandatory')
      }

      api = Api(context, purchasingRequisition.saveRevision(formData));
      api.onSuccess(function(response) {
        context.get();
        context.getDetailTransaction(context.headerData.pr_no);
        context.itemData = {};
        context.notify((context.formSub.title === '==== TRANSACTION PR ADD DATA ') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.formSub.title != '==== TRANSACTION PR ADD DATA ') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.formRevisiItem.show = false;
      })
      .call();
    },
    showRevision(item_pr_code){
      let context = this;               
      Api(context, purchasingRequisition.showRevision(item_pr_code)).onSuccess(function(response) {
          context.tableShowRevision.data = response.data.data;
          context.formShowRevision.show  = true;
          context.formShowRevision.title = 'Revision Data';   
      })
      .call() 
    },
    remove(id, total_item, transaction_type) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        // if ((transaction_type == 'Reduction') || (total_item == 0)) {
          let context = this;
          Api(context, purchasingRequisition.delete(id)).onSuccess(function(response) {
              context.get();
              context.notify('Data Successfully Deleted', 'success')
          }).call();
        // }else{
        //   this.notify('Failed to delete. Please clear items first', 'error')
        // }
      }
    },
    removeItem(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context  = this;

        Api(context, purchasingRequisition.deleteItem(id)).onSuccess(function(response) {
            context.get();
            context.getDetailTransaction(context.headerData.pr_no);
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    prApproved(param){
      let api      = null;
      let context  = this;
      context.onLoading = true;

      api = Api(context, purchasingRequisition.prApproved({pr_code: param}));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getDetailTransaction(context.headerData.pr_no)
        context.get();
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import PR Item";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      // INSERT HEADER
      if (context.headerData.project_type != undefined && context.headerData.wo_no != undefined && context.headerData.material_group_code != undefined && context.headerData.client != undefined && context.headerData.date_required != undefined && context.headerData.contract_delivery != undefined && context.headerData.status != undefined ) {
        formData.append('pr_no', (context.headerData.pr_no == undefined) ? '' : context.headerData.pr_no);
        formData.append('project_type', context.headerData.project_type);
        formData.append('wo_no', JSON.stringify(context.headerData.wo_no));
        formData.append('material_group_code', JSON.stringify(context.headerData.material_group_code));
        formData.append('date_required', context.headerData.date_required);
        formData.append('status', context.headerData.status);
        formData.append('general_note', (context.headerData.general_note == undefined) ? '-' : context.headerData.general_note);
        formData.append('client', context.headerData.client);
        formData.append('contract_delivery', context.headerData.contract_delivery);
        formData.append('item_no', (context.headerData.item_no == undefined) ? '-' : context.headerData.item_no);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field Header is Mandatory')
      }

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        context.onLoading = false;
        return alert('File Import Not Found')
      }

      api = Api(context, purchasingRequisition.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.getDetailTransaction(response.data.data);
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.headerData.date = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll-model { 
  overflow: auto;  
}
.scroll-model thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
