<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Employee</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder ">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  ps-2">Department</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">NO KTP</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">No HP</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Email</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Shift</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">No Rek</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">A/N REk</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Bank</th>
                      <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">NO BPJS TK</th> -->
                      <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">NO BPJS KES</th> -->
                      <th class="text-secondary"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td>
                        <router-link :to="/detail-profile/+row.nik">
                          <div class="d-flex px-2 py-1">
                            <div>
                              <img
                                v-if="row.foto_karyawan"
                                :src="storageUrl+'/foto_karyawan/'+row.foto_karyawan"
                                class="avatar avatar-sm me-3"
                                alt="user1"
                              />
                              <img
                                v-else
                                src="../../assets/img/avatar_karyawan.png"
                                class="avatar avatar-sm me-3"
                                alt="user1"
                              />
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                              <p class="text-xs text-secondary mb-0">{{ row.position}}</p>
                            </div>
                          </div>
                        </router-link>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">{{ row.department }}</p>
                        <p class="text-xs text-secondary mb-0">{{ row.nik }}</p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-success" v-if="row.employee_status == 'Permanent'">{{ row.employee_status }}</span>
                        <span class="badge badge-sm bg-gradient-danger" v-else>{{ row.employee_status }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.no_ktp}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a style="color: blue;" target="_blank" :href="'https://api.whatsapp.com/send?phone=62'+row.no_hp+'&text=Assalamualaikum Hallo Pak ' +row.name+','">{{row.no_hp}} </a>
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.email}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.shift}}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.no_rek }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.an_rek }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.bank }}</span>
                      </td>
                      <!-- <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">096747565</span>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">KAS-K65758</span>
                      </td> -->
                      <td>
                        <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" ></i> -->
                        <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <span class="font-weight-bold">TOTAL ROWS: {{ table.data.length }}</span>
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <!-- <label for="example-text-input" class="form-control-label mt-3">NIK <span style="color: red;">*</span></label> -->
        <!-- <input type="text" class="form-control" placeholder="NIK" v-model="employeeData.nik"> -->
        <label for="example-text-input" class="form-control-label mt-3">Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Name" v-model="employeeData.name" required>
        <label for="example-text-input" class="form-control-label mt-3">Department <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Department" v-model="employeeData.department">
        <label for="example-text-input" class="form-control-label mt-3">Position <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Position" v-model="employeeData.position" required>
        <label for="example-text-input" class="form-control-label mt-3">Employee Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="employeeData.employee_status">
          <option value="Permanent">Permanent</option>
          <option value="Contract">Contract</option>
          <option value="Daily">Daily</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Date Of Hire</label>
        <input type="date" class="form-control" placeholder="Date Of Hire" v-model="employeeData.date_of_hire">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Education <span style="color: red;">*</span></label> -->
        <!-- <input type="number" class="form-control" placeholder="Education" v-model="employeeData.education" required> -->
        <!-- <label for="example-text-input" class="form-control-label mt-3">Bulanan</label>
        <input type="number" class="form-control" placeholder="Bulanan" v-model="employeeData.bulanan">
        <label for="example-text-input" class="form-control-label mt-3">TJ Jabatan / Skill</label>
        <input type="number" class="form-control" placeholder="TJ Jabatan / Skill" v-model="employeeData.tj_jabatan_skill">
        <label for="example-text-input" class="form-control-label mt-3">Transport</label>
        <input type="number" class="form-control" placeholder="Transport" v-model="employeeData.transport">
        <label for="example-text-input" class="form-control-label mt-3">Makan</label>
        <input type="number" class="form-control" placeholder="Makan" v-model="employeeData.makan">
        <label for="example-text-input" class="form-control-label mt-3">Nama Bank</label>
        <input type="text" class="form-control" placeholder="Nama Bank" v-model="employeeData.bank">
        <label for="example-text-input" class="form-control-label mt-3">No Rekening</label>
        <input type="text" class="form-control" placeholder="No Rekening" v-model="employeeData.no_rek">
        <label for="example-text-input" class="form-control-label mt-3">Atas Nama Rekening</label>
        <input type="text" class="form-control" placeholder="Atas Nama Rekening" v-model="employeeData.an_rek">
        <label for="example-text-input" class="form-control-label mt-3">NO BPJS TK</label>
        <input type="text" class="form-control" placeholder="NO BPJS TK" v-model="employeeData.no_bpjs_tk">
        <label for="example-text-input" class="form-control-label mt-3">NO BPJS KES</label>
        <input type="text" class="form-control" placeholder="NO BPJS KES" v-model="employeeData.no_bpjs_kes"> -->
        <!-- <label for="example-text-input" class="form-control-label mt-3">Total Cuti <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Total Cuti" v-model="employeeData.total_cuti" required> -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">NIK</label>
        <input type="text" class="form-control" placeholder="NIK" v-model="search.nik">
        <label for="example-text-input" class="form-control-label mt-3">Name</label>
        <input type="text" class="form-control" placeholder="Name" v-model="search.name" required>
        <label for="example-text-input" class="form-control-label mt-3">Position</label>
        <input type="text" class="form-control" placeholder="Position" v-model="search.position" required>
        <label for="example-text-input" class="form-control-label mt-3">Department</label>
        <input type="text" class="form-control" placeholder="Department" v-model="search.department" required>
        <label for="example-text-input" class="form-control-label mt-3">Employee Status </label>
        <select class="form-select" aria-label="Default select example" v-model="search.employee_status">
          <option value="Permanent">Permanent</option>
          <option value="Contract">Contract</option>
          <option value="Daily">Daily</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Shift </label>
        <select class="form-select" aria-label="Default select example" v-model="search.shift">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="Non">Non</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilter.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChange" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Data Karyawan.xlsx'" style="color: blue;"> Import Data Karyawan</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center;">Column</th>
                  <th style="font-size: 13px; text-align: center;">Error</th>
                  <th style="font-size: 13px; text-align: center;">Row</th>
                  <th style="font-size: 13px; text-align: center;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import employee from '@/services/human_resource/employee.service';
import akun from '@/services/akun.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    BasePagination,
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Karyawan",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      employeeData: {},
      search: {
        nik: '',
        name: '',
        position: '',
        department: '',
        employee_status: '',
        shift: '',
      },
      apiUrl :config.apiUrl,
      role: '',
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    // this.getRole();
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, employee.index({nik: context.search.nik, name: context.search.name, position: context.search.position, department: context.search.department, employee_status: context.search.employee_status, shift: context.search.shift })).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role = response.data.data[0].role;
          if (response.data.data[0].role != 'Admin') {
            context.$router.push({ path: /detail-profile/+response.data.data[0].employee_id})
          }
      }).onError(function(error) {  
      })
      .call() 
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.karyawan   = {};
    },
    // edit(id) {
    //   let context = this;               
    //   Api(context, employee.show(id)).onSuccess(function(response) {
    //     console.log(response.data.data)
    //       context.karyawan   = response.data.data;
    //       context.form.show  = true;
    //       context.form.title = 'Edit Data';       
    //   })
    //   .call()        
    // },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.employeeData.name != undefined && context.employeeData.department != undefined && context.employeeData.position != undefined && context.employeeData.employee_status != undefined && context.employeeData.date_of_hire != undefined) {
        formData.append('nik', context.employeeData.nik);
        formData.append('name', context.employeeData.name);
        formData.append('department', context.employeeData.department);
        formData.append('position', context.employeeData.position);
        formData.append('employee_status', context.employeeData.employee_status);
        formData.append('date_of_hire', context.employeeData.date_of_hire);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, employee.create(formData));
      } else {
          api = Api(context, employee.update(this.karyawan.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Anda yakin ingin menghapus data ini ?");
      if (r == true) {
        let context = this;

        Api(context, employee.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Karyawan";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, employee.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
