<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-success" style="margin-right: 10px;" :href="apiUrl+'export-excel/daily-activity?project_type='+search.project_type+'&po_number='+search.po_number+'&c_date='+search.c_date+'&client='+search.client+'&owner='+search.owner+'&site='+search.site+'&kinds='+search.kinds+'&due_date='+search.due_date+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'print-pdf/daily-activity?project_type='+search.project_type+'&po_number='+search.po_number+'&c_date='+search.c_date+'&client='+search.client+'&owner='+search.owner+'&site='+search.site+'&kinds='+search.kinds+'&due_date='+search.due_date+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Master Item List</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM CODE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SPECIFICATION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SIZE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">UNIT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM GROUP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM CATEGORY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CREATED AT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CREATED BY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.item_code }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.item_name }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.specification }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.size }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.unit }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.item_group }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.item_category }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.created_at).locale('id').format('LL') }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.created_by }} </span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <!-- <span class="font-weight-bold text-danger ">TOTAL WORKING HOUR : {{ totalWorkingHour }} Hour {{ totalWorkingMinutes }} Minutes</span> -->
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Item Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Item Name" v-model="itemData.item_name">
        <label for="example-text-input" class="form-control-label mt-3">Specification <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Specification" v-model="itemData.specification">
        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Size" v-model="itemData.size">
        <label for="example-text-input" class="form-control-label mt-3">Unit <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="itemData.unit">
          <option value="Batang">Batang</option>
          <option value="Box">Box</option> 
          <option value="Btl">Btl</option>
          <option value="Can">Can</option>
          <option value="Cyl">Cyl</option>
          <option value="Drum">Drum</option>
          <option value="Ea">Ea</option>
          <option value="Kg">Kg</option>
          <option value="Kaleng">Kaleng</option>
          <option value="Lembar">Lembar</option>
          <option value="Liter">Liter</option>
          <option value="Lusin">Lusin</option>
          <option value="Mtr">Mtr</option>
          <option value="M3">M3</option>
          <option value="Pack">Pack</option>
          <option value="Pasang">Pasang</option>
          <option value="Pcs">Pcs</option>
          <option value="Rim">Rim</option>
          <option value="Roll">Roll</option>
          <option value="Set">Set</option>
          <option value="Tbg">Tbg</option>
          <option value="Unit">Unit</option>        
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Item Group <span style="color: red;">*</span></label>
        <multiselect v-model="itemData.item_group" :options="tableItemGroup.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Item Group" label="group_name" track-by="group_name" :preselect-first="true" @keyup="getItemGroup">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Item Category </label>
        <multiselect v-model="itemData.item_category" :options="tableItemCategory.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Item Category" label="category_name" track-by="category_name" :preselect-first="true" @keyup="getItemCategory">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Code </label>
        <input type="text" class="form-control" placeholder="Code" v-model="search.item_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Material / Services Name </label>
        <input type="text" class="form-control" placeholder="Material / Services Name" v-model="search.group_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Description </label>
        <input type="text" class="form-control" placeholder="Description" v-model="search.description" v-on:keyup.enter="get()">

        <!-- <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/> -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import masterItemList from '@/services/procurement/masterItemList.service';
import materialGroupPR from '@/services/procurement/materialGroupPR.service';
import itemCategory from '@/services/procurement/itemCategory.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    // flatPickr,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableItemGroup: {
        data: []
      },
      tableItemCategory: {
        data: []
      },
      
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      itemData: {},
      search: {
        item_name: '',
        group_name: '',
        description: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalWorkingHour: '',
      totalWorkingMinutes: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, masterItemList.index({item_name: this.search.item_name, group_name: this.search.group_name, description: this.search.description, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getItemGroup(param){
      let context = this;        
      Api(context, materialGroupPR.getMaterialGroupPR({search: param.target.value})).onSuccess(function(response) {    
          context.tableItemGroup.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItemGroup.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getItemCategory(param){
      let context = this;        
      Api(context, itemCategory.getMaterialCategoryPR({search: param.target.value})).onSuccess(function(response) {    
          context.tableItemCategory.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItemCategory.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add          = true;
      this.form.show         = true;
      this.form.title        = "Add Data";
      this.itemData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, masterItemList.show(id)).onSuccess(function(response) {
          context.itemData            = response.data.data.data;
          context.itemData.item_group = response.data.data.dataItemGroup;
          context.form.show           = true;
          context.form.title          = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.itemData.item_name != undefined && context.itemData.specification != undefined && context.itemData.size != undefined && context.itemData.unit != undefined && context.itemData.item_group != undefined) {
        formData.append('item_name', context.itemData.item_name);
        formData.append('specification', context.itemData.specification);
        formData.append('size', context.itemData.size);
        formData.append('unit', context.itemData.unit);
        formData.append('item_group', JSON.stringify(context.itemData.item_group));
        formData.append('item_category', (JSON.stringify(context.itemData.item_category) == undefined) ? '' : JSON.stringify(context.itemData.item_category));
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, masterItemList.create(formData));
      } else {
          api = Api(context, masterItemList.update(this.itemData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;
        Api(context, masterItemList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.itemData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
